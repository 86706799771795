<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <m-widget-container-form v-model="value" />
      <m-array-form
        v-model="value.items"
        name="Warning"
        item-subtitle="subtitle"
      >
        <template #default="{ editItem }">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Title"
          >
            <v-text-field
              v-model="editItem.title"
              outlined
              label="Title"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Subtitle"
          >
            <v-textarea
              v-model="editItem.subtitle"
              label="Subtitle"
              auto-grow
              required
              outlined
              rows="3"
              :error-messages="errors"
            />
          </validation-provider>
        </template>
      </m-array-form>
      <field-card label="Design">
        <m-color-input
          v-model="value.iconColor"
          label="Icon Color"
        />
        <m-color-input
          v-model="value.titleColor"
          label="Title Color"
        />
        <m-color-input
          v-model="value.textColor"
          label="Subtitle Color"
        />
        <v-row class="flex-wrap">
          <v-switch
            v-model="value.dark"
            class="mx-5"
            label="Dark Mode"
          />
        </v-row>
      </field-card>
      <field-card label="Behavior">
        <v-row class="flex-wrap ">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Seconds per Warning"
          >
            <v-text-field
              v-model="value.intervalInSeconds"
              class="mx-5"
              label="Interval"
              hint="Duration for each warning in seconds"
              outlined
              type="number"
              required
              :error-messages="errors"
            />
          </validation-provider>
          <v-switch
            v-model="value.vertical"
            class="mx-5"
            label="Slide Vertically"
          />
        </v-row>
      </field-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MWarningForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  })
}
</script>
