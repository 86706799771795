var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.error)?_c('v-card-text',{staticClass:"mb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('v-card-text',[_c('m-widget-container-form',{model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('m-array-form',{attrs:{"name":"Warning","item-subtitle":"subtitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Title","required":"","error-messages":errors},model:{value:(editItem.title),callback:function ($$v) {_vm.$set(editItem, "title", $$v)},expression:"editItem.title"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"Subtitle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Subtitle","auto-grow":"","required":"","outlined":"","rows":"3","error-messages":errors},model:{value:(editItem.subtitle),callback:function ($$v) {_vm.$set(editItem, "subtitle", $$v)},expression:"editItem.subtitle"}})]}}],null,true)})]}}]),model:{value:(_vm.value.items),callback:function ($$v) {_vm.$set(_vm.value, "items", $$v)},expression:"value.items"}}),_c('field-card',{attrs:{"label":"Design"}},[_c('m-color-input',{attrs:{"label":"Icon Color"},model:{value:(_vm.value.iconColor),callback:function ($$v) {_vm.$set(_vm.value, "iconColor", $$v)},expression:"value.iconColor"}}),_c('m-color-input',{attrs:{"label":"Title Color"},model:{value:(_vm.value.titleColor),callback:function ($$v) {_vm.$set(_vm.value, "titleColor", $$v)},expression:"value.titleColor"}}),_c('m-color-input',{attrs:{"label":"Subtitle Color"},model:{value:(_vm.value.textColor),callback:function ($$v) {_vm.$set(_vm.value, "textColor", $$v)},expression:"value.textColor"}}),_c('v-row',{staticClass:"flex-wrap"},[_c('v-switch',{staticClass:"mx-5",attrs:{"label":"Dark Mode"},model:{value:(_vm.value.dark),callback:function ($$v) {_vm.$set(_vm.value, "dark", $$v)},expression:"value.dark"}})],1)],1),_c('field-card',{attrs:{"label":"Behavior"}},[_c('v-row',{staticClass:"flex-wrap "},[_c('validation-provider',{attrs:{"rules":"required","name":"Seconds per Warning"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mx-5",attrs:{"label":"Interval","hint":"Duration for each warning in seconds","outlined":"","type":"number","required":"","error-messages":errors},model:{value:(_vm.value.intervalInSeconds),callback:function ($$v) {_vm.$set(_vm.value, "intervalInSeconds", $$v)},expression:"value.intervalInSeconds"}})]}}])}),_c('v-switch',{staticClass:"mx-5",attrs:{"label":"Slide Vertically"},model:{value:(_vm.value.vertical),callback:function ($$v) {_vm.$set(_vm.value, "vertical", $$v)},expression:"value.vertical"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }